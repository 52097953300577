import { defineStore } from 'pinia'
import { getDebugger } from '~/libs/debug'
import type { DocumentStatuses, AccountStatuses } from '~/utils/useStatusFunctions'
import type { Axios } from 'axios'
import type { PandaTsCountryAlpha2 } from '~/libs/countries'
import type { PoiDocumentTypes, PorDocumentTypes } from '~/libs/validation'

export type JsonPrimitive = string | number | boolean | null

export type Jsonable = JsonPrimitive | { [key: string]: Jsonable } | Jsonable[]

export type Jurisdiction = PandaTsCountryAlpha2 | 'XX'

export interface AnswerOption {
  value: Jsonable
  i18n: string
  en: string
}

export interface Question {
  type: 'text' | 'number' | 'date' | 'datetime' | 'select' | 'switch'
  castAs: 'string' | 'number' | 'date' | 'datetime' | 'boolean'
  key: string
  i18n: string
  en: string
  schema: string | null
  options: AnswerOption[] // Made optional to reflect your structure
  current: JsonPrimitive | null // Adjusted to align with JsonPrimitive for more specific typing
}

export interface DocumentStatus {
  status: DocumentStatuses
  type?: PoiDocumentTypes | PorDocumentTypes
  hasBack?: boolean
  docFront?: DocumentStatusDetail
  docBack?: DocumentStatusDetail | null
}

export interface DocumentStatusDetail {
  status: DocumentStatuses
}

export interface RsaId {
  has: boolean
  value?: string // Assuming value is optional based on the presence of 'has'
}

export interface Status {
  verificationLevel: string | null
  guiType: 'sumsub' | 'default'
  status: 'complete' | 'incomplete' | 'pending'
  canTrade: boolean
  questionnaireApprovalStatus: DocumentStatuses
  questionnaire: Question[]
  jurisdiction: Jurisdiction
  rsaId: RsaId
  poi: DocumentStatus
  por: DocumentStatus
}

const debug = getDebugger('Status')

export const useStatusStore = defineStore({
  id: 'statusStore',
  state: () => ({
    loaded: false,
    processing: false,
    raw: undefined as Status | undefined,
    fetchAbortController: undefined as AbortController | undefined,
  }),
  getters: {
    gui: (state): 'sumsub' | 'default' => (state.raw ? state.raw.guiType : 'default'),
    level: (state): string | null => (state.raw ? state.raw.verificationLevel : null),
    accountStatus: (state): AccountStatuses => (state.raw ? state.raw.status : 'unknown'),
    accountCanTrade: (state): boolean => (state.raw ? state.raw.canTrade : false),
    rsaIdStatus: (state): DocumentStatuses =>
      state.raw ? (state.raw.rsaId.has ? 'approved' : 'none') : 'unknown',
    poiStatus: (state): DocumentStatuses => (state.raw ? state.raw.poi.status : 'unknown'),
    porStatus: (state): DocumentStatuses => (state.raw ? state.raw.por.status : 'unknown'),
    questionnaireStatus: (state): DocumentStatuses =>
      state.raw ? state.raw.questionnaireApprovalStatus : 'unknown',
    questionnaireQuestions: (state): Question[] =>
      // @ts-ignore it's never too deep for strong typing
      state.raw ? state.raw.questionnaire : ([] as Question[]),
  },
  actions: {
    onLogout() {
      this.$state.loaded = false
      this.$state.raw = undefined
    },
    async fetch(api: Axios) {
      if (this.$state.fetchAbortController) {
        this.$state.fetchAbortController.abort()
      }
      this.$state.fetchAbortController = new AbortController()
      try {
        this.$state.processing = true
        const { status: requestStatus, data } = await api.get('/app/status', {
          signal: this.$state.fetchAbortController.signal,
        })
        this.$state.processing = false
        if (requestStatus === 200 && data && data.payload) {
          this.$state.raw = data.payload
          this.$state.loaded = true
        }
      } catch (error) {
        if (error instanceof Error && error.name !== 'CanceledError') {
          debug(error)
          return
        }
      }
    },
  },
})
