import Swal from 'sweetalert2'
import { config, config as vuetifyConfig } from '../libs/vuetify'

export default defineNuxtPlugin((nuxtApp) => {
  const defaults = config.defaults?.Swal || {}
  const swal = Swal.mixin(defaults)
  nuxtApp.vueApp.provide('swal', swal)
  nuxtApp.vueApp.config.globalProperties.$swal = swal
  const toastDefaults = Object.assign({}, defaults, {
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    backdrop: undefined,
    didOpen: (toast: HTMLElement) => {
      toast.onmouseenter = Swal.stopTimer
      toast.onmouseleave = Swal.resumeTimer
    },
  }) as any
  delete toastDefaults.backdrop
  const toast = Swal.mixin(toastDefaults)
  nuxtApp.vueApp.provide('toast', toast)
  nuxtApp.vueApp.config.globalProperties.$toast = toast
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $swal?: typeof Swal
    $toast?: typeof Swal
  }
  interface ComponentCustomProps {
    $swal?: typeof Swal
    $toast?: typeof Swal
  }
  interface InjectionKey<T> extends Symbol {
    swal: typeof Swal
    toast: typeof Swal
  }
}
