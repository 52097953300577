import Hotjar from '@hotjar/browser'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const { VITE_HOTJAR_ID, VITE_HOTJAR_VERSION } = runtimeConfig.public
  Hotjar.init(parseInt(VITE_HOTJAR_ID), parseInt(VITE_HOTJAR_VERSION), {
    // debug: 'production' !== process.env.NODE_ENV,
    debug: false,
  })
  nuxtApp.vueApp.provide('hotjar', Hotjar)
  nuxtApp.vueApp.config.globalProperties.$hotjar = Hotjar
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $hotjar?: typeof Hotjar
  }
  interface ComponentCustomProps {
    $hotjar?: typeof Hotjar
  }
  interface InjectionKey<T> extends Symbol {
    hotjar: typeof Hotjar
  }
}
