import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: 'https://08678e2c19354757bd1a93f3908b41e3@apm.banxso.com/26',
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/app-verify\./],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    release: import.meta.env.VITE_VERSION || 'development',
  })
})
