import axios, { Axios } from 'axios'
import { getDebugger } from '../libs/debug'

const debug = getDebugger('plugins:api')

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  debug(`API Base URL: ${runtimeConfig.public.VITE_API_HOST}`)
  const instance = axios.create({
    baseURL: runtimeConfig.public.VITE_API_HOST,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: () => true,
  })
  instance.interceptors.request.use(
    (config) => {
      if (
        nuxtApp.vueApp.config.globalProperties.$ls &&
        nuxtApp.vueApp.config.globalProperties.$ls.loaded
      ) {
        const bearer = nuxtApp.vueApp.config.globalProperties.$ls.get('bearer')
        if (bearer) {
          config.headers.Authorization = `Bearer ${bearer}`
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  instance.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        if (nuxtApp.vueApp.config.globalProperties.$bus) {
          nuxtApp.vueApp.config.globalProperties.$bus.emit('unauthorized', {
            local: true,
            crossTab: true,
          })
        }
      }
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        if (nuxtApp.vueApp.config.globalProperties.$bus) {
          nuxtApp.vueApp.config.globalProperties.$bus.emit('unauthorized', {
            local: true,
            crossTab: true,
          })
        }
      }
      return Promise.reject(error)
    }
  )
  nuxtApp.vueApp.provide('api', instance)
  nuxtApp.vueApp.config.globalProperties.$api = instance
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $api?: Axios
  }
  interface ComponentCustomProps {
    $api?: Axios
  }
  interface InjectionKey<T> extends Symbol {
    api: Axios
  }
}
