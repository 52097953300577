import { PushService } from '../libs/push'
import type { FirebaseOptions } from 'firebase/app'
import type { PwaInjection } from '@vite-pwa/nuxt/dist/runtime/plugins/types.d.ts'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const firebaseOptions: FirebaseOptions = {
    apiKey: runtimeConfig.public.VITE_FCM_CONFIG_API_KEY,
    authDomain: runtimeConfig.public.VITE_FCM_CONFIG_AUTH_DOMAIN,
    projectId: runtimeConfig.public.VITE_FCM_CONFIG_PROJECT_ID,
    storageBucket: runtimeConfig.public.VITE_FCM_CONFIG_STORAGE_BUCKET,
    messagingSenderId: runtimeConfig.public.VITE_FCM_CONFIG_MESSAGING_SENDER_ID,
    appId: runtimeConfig.public.VITE_FCM_CONFIG_APP_ID,
    measurementId: runtimeConfig.public.VITE_FCM_CONFIG_MEASUREMENT_ID,
  }
  const instance = new PushService(
    nuxtApp.vueApp.config.globalProperties.$bus!,
    nuxtApp.vueApp.config.globalProperties.$ls!,
    nuxtApp.vueApp.config.globalProperties.$cron!,
    nuxtApp.vueApp.config.globalProperties.$api!,
    nuxtApp.vueApp.config.globalProperties.$identity!,
    firebaseOptions,
    nuxtApp.$pwa as PwaInjection
  )
  nuxtApp.vueApp.provide('push', instance)
  nuxtApp.vueApp.config.globalProperties.$push = instance
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $push?: PushService
  }
  interface ComponentCustomProps {
    $push?: PushService
  }
  interface InjectionKey<T> extends Symbol {
    push: PushService
  }
}
