<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { colorToCssWithAlpha } from '~/libs/colors'
import { getDebugger } from './libs/debug'
import type { PropType } from 'vue'
import type { NuxtError } from '#app'

const debug = getDebugger('ErrorPage')

export default defineComponent({
  name: 'ErrorPage',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default: null,
    },
  },
  setup(props) {
    const error = computed(() => props.error)
    onMounted(() => {
      debug(error.value)
    })
    return {
      loader: {
        color: colorToCssWithAlpha('primary'),
      },
    }
  },
})
</script>

<template>
  <NuxtLayout name="error">
    <VitePwaManifest />
    <NuxtLoadingIndicator :color="loader.color" />
    <!-- <NuxtPage /> -->
  </NuxtLayout>
</template>
