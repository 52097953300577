import { default as Resumable } from 'resumablejs'
import type { LocalStorage } from './localStorage'
import { filesize } from 'filesize'
import mime2ext from 'mime2ext'
import { getDebugger } from './debug'

const debug = getDebugger('resumable')

export class FakeResumable {
  public support: boolean = false // Assuming support is true for simplicity
  public opts: Resumable.ConfigurationHash
  public files: Resumable.ResumableFile[] = []
  public defaults: Resumable.ConfigurationHash = {}
  public events: Event[] = []
  public version: number = 1.0

  constructor(options: Resumable.ConfigurationHash) {
    this.opts = options
  }

  public assignBrowse(domNode: Element | Element[], isDirectory: boolean = false): void {
    // Mimic functionality if necessary
  }

  public assignDrop(domNode: Element | Element[]): void {
    // Mimic functionality if necessary
  }

  public unAssignDrop(domNode: Element | Element[]): void {
    // Mimic functionality if necessary
  }

  public upload(): void {
    // Mimic functionality if necessary
  }

  public uploadNextChunk(): void {
    // Mimic functionality if necessary
  }

  public pause(): void {
    // Mimic functionality if necessary
  }

  public cancel(): void {
    this.files = [] // Clear the files array
  }

  public fire(): void {
    // Mimic functionality if necessary
  }

  public progress(): number {
    // Return a fake progress, e.g., 0.5 for 50%
    return 0.5
  }

  public isUploading(): boolean {
    // Return a fake uploading state
    return false
  }

  public addFile(file: File, _event?: Event): void {
    return
  }

  public removeFile(_file: Resumable.ResumableFile): void {
    return
  }

  public getFromUniqueIdentifier(uniqueIdentifier: string): Resumable.ResumableFile | undefined {
    return this.files.find((file) => file.uniqueIdentifier === uniqueIdentifier)
  }

  public getSize(): number {
    return this.files.reduce((total, file) => total + file.file.size, 0)
  }

  public getOpt(o: keyof Resumable.ConfigurationHash): any {
    return this.opts[o]
  }

  public handleChangeEvent(e: Event): void {
    // Mimic functionality if necessary
  }

  public handleDropEvent(e: Event): void {
    // Mimic functionality if necessary
  }

  public on(event: string, callback: Function): void {
    // Mimic event registration
  }
}

export type ResumableLike = Resumable | FakeResumable

export const createResumableInstance = (hostname: string, pathname: string, ls: LocalStorage) => {
  const hostUrl = new URL(hostname)
  let hostPath = hostUrl.pathname
  while (hostPath.endsWith('/')) {
    hostPath = hostPath.slice(0, -1)
  }
  pathname = hostPath + pathname
  const targetUrl = new URL(pathname, hostname)
  debug('Creating Resumable instance for', targetUrl.toString())
  const instance = new Resumable({
    target: targetUrl.toString(),
    // @ts-ignore due to bad type definition
    testTarget: targetUrl.toString(),
    simultaneousUploads: 2,
    // @ts-ignore due to bad type definition
    testMethod: 'HEAD',
    uploadMethod: 'PUT',
    headers: (_resumableFile: unknown, resumableChunk: any) => {
      // eslint-disable-next-line no-control-regex
      const incompatibleHeader = /[^\u0000-\u00ff]/
      const ret: any = {
        'resumable-chunk-number': resumableChunk.offset + 1,
        'resumable-chunk-size': resumableChunk.getOpt('chunkSize'),
        'resumable-current-chunk-size': resumableChunk.endByte - resumableChunk.startByte,
        'resumable-total-size': resumableChunk.fileObjSize,
        'resumable-type': resumableChunk.fileObjType,
        'resumable-identifier': resumableChunk.fileObj.uniqueIdentifier,
        'resumable-filename': resumableChunk.fileObj.fileName,
        'resumable-relative-path': resumableChunk.fileObj.relativePath,
        'resumable-total-chunks': resumableChunk.fileObj.chunks.length,
        // 'minfraud-checksum': getMinfraudDeviceFingerprint(),
      }
      if (ls.loaded) {
        const token = ls.get('bearer')
        ret.Authorization = `Bearer ${token}`
      }
      for (const key in ret) {
        if (incompatibleHeader.test(ret[key])) {
          ret[`${key}-base64`] = btoa(encodeURIComponent(ret[key]))
          delete ret[key]
        }
      }
      return ret
    },
    chunkRetryInterval: 500,
    permanentErrors: [
      400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418,
      421, 422, 423, 424, 425, 426, 428, 429, 431, 451, 500, 501, 502, 503, 504, 505, 506, 507, 508,
      510, 511, 520, 521, 522, 523, 524, 525, 526, 527, 530,
    ],
  })
  return instance
}

export const toHuman = filesize

export const fromHuman = (size: string) => {
  const units = {
    B: 1,
    KB: 1000,
    MB: 1000 ** 2,
    GB: 1000 ** 3,
    TB: 1000 ** 4,
  }

  const regex = /(\d+(\.\d+)?)\s?(B|KB|MB|GB|TB)/i
  const matches = size.match(regex)

  if (!matches) {
    return undefined
  }

  const [, number, , unit] = matches
  return parseFloat(number) * units[unit.toUpperCase() as keyof typeof units]
}

export const toHumanFileType = (type: string, additionalMappings: Record<string, string> = {}) => {
  const mapping: Record<string, string> = Object.assign(
    {},
    {
      'image/jpeg': 'JPEG Image',
      'image/png': 'PNG Image',
      'image/gif': 'GIF Image',
      'image/webp': 'WebP Image',
      'image/svg+xml': 'SVG Image',
      'application/pdf': 'PDF Document',
      'text/plain': 'Text File',
      'text/html': 'HTML File',
      'text/css': 'CSS File',
      'application/javascript': 'JavaScript File',
      'application/json': 'JSON File',
      'audio/mpeg': 'MP3 Audio',
      'audio/wav': 'WAV Audio',
      'video/mp4': 'MP4 Video',
    },
    additionalMappings
  )
  return mapping[type] || mime2ext(type) || 'Unknown File Type'
}

export const fileTypeMagicNumberValidators: Record<string, (uintArray: Uint8Array) => boolean> = {
  'image/jpeg': (uintArray: Uint8Array) =>
    uintArray[0] === 0xff && uintArray[1] === 0xd8 && uintArray[2] === 0xff,
  'image/png': (uintArray: Uint8Array) =>
    uintArray[0] === 0x89 &&
    uintArray[1] === 0x50 &&
    uintArray[2] === 0x4e &&
    uintArray[3] === 0x47,
  'image/gif': (uintArray: Uint8Array) =>
    uintArray[0] === 0x47 && uintArray[1] === 0x49 && uintArray[2] === 0x46,
  'image/webp': (uintArray: Uint8Array) =>
    uintArray[8] === 0x57 &&
    uintArray[9] === 0x45 &&
    uintArray[10] === 0x42 &&
    uintArray[11] === 0x50,
  'application/pdf': (uintArray: Uint8Array) =>
    uintArray[0] === 0x25 &&
    uintArray[1] === 0x50 &&
    uintArray[2] === 0x44 &&
    uintArray[3] === 0x46,
  'audio/mpeg': (uintArray: Uint8Array) => uintArray[0] === 0xff && (uintArray[1] & 0xe0) === 0xe0,
  'audio/wav': (uintArray: Uint8Array) =>
    uintArray[0] === 0x52 &&
    uintArray[1] === 0x49 &&
    uintArray[2] === 0x46 &&
    uintArray[3] === 0x46,
  'video/mp4': (uintArray: Uint8Array) => {
    const isMP4Type1 =
      uintArray[0] === 0x00 &&
      uintArray[1] === 0x00 &&
      uintArray[2] === 0x00 &&
      (uintArray[3] === 0x18 || uintArray[3] === 0x20) &&
      uintArray[4] === 0x66 &&
      uintArray[5] === 0x74 &&
      uintArray[6] === 0x79 &&
      uintArray[7] === 0x70
    const isMP4Type2 =
      uintArray[0] === 0x33 &&
      uintArray[1] === 0x67 &&
      uintArray[2] === 0x70 &&
      uintArray[3] === 0x35
    // Add other variations as needed
    return isMP4Type1 || isMP4Type2
  },
}

export const validateFileIsAcceptedType = async (file: File, types: Array<string>) => {
  const results = await Promise.all(types.map((type) => validateFileIsType(file, type)))
  const is = results.some((result) => result)
  if (!is) {
    throw new Error('File type is not accepted')
  }
  return is
}

export const validateFileIsType = async (file: File, type: string) => {
  const reader = new FileReader()
  const uintPromise = new Promise<Uint8Array>((resolve) => {
    reader.onloadend = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.readyState === FileReader.DONE && null !== e.target.result) {
        if ('string' === typeof e.target.result) {
          const uint = Uint8Array.from(e.target.result, (c) => c.charCodeAt(0))
          resolve(uint)
        } else {
          const uint = new Uint8Array(e.target.result)
          resolve(uint)
        }
      }
    }
  })
  reader.readAsArrayBuffer(file.slice(0, 10))
  const uint = await uintPromise
  const validator =
    fileTypeMagicNumberValidators[type as keyof typeof fileTypeMagicNumberValidators]
  if (!validator) {
    // if we don't have a validator, accept that the file is of the given type
    return true
  }
  return validator(uint)
}
