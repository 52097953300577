import { LocalStorage } from '../libs/localStorage'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const instance = new LocalStorage(runtimeConfig.public.namespace)
  nuxtApp.vueApp.provide('ls', instance)
  nuxtApp.vueApp.config.globalProperties.$ls = instance
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $ls?: LocalStorage
  }
  interface ComponentCustomProps {
    $ls?: LocalStorage
  }
  interface InjectionKey<T> extends Symbol {
    ls: LocalStorage
  }
}
