import revive_payload_client_4sVQNw7RlN from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import pwa_client_Eorgdr12jA from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_yfWm7jX06p from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_sentry_MgFydIFahU from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/00.sentry.ts";
import _01_bus_uVOTHEAQvV from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/01.bus.ts";
import _01_cron_VFpgOqTWAz from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/01.cron.ts";
import _01_hotjar_client_eGAfoWrJCs from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/01.hotjar.client.ts";
import _01_localStorage_OTdFX3RGvx from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/01.localStorage.ts";
import _01_vuetify_ldQcUM9Q1b from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/01.vuetify.ts";
import _02_api_bgyRz33mcA from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/02.api.ts";
import _10_identity_KRcqj1o6Px from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/10.identity.ts";
import _15_push_client_mN3a0hmkLI from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/15.push.client.ts";
import _20_resumable_MUpQ4xvMld from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/20.resumable.ts";
import _20_swal_client_vczlhVeYNn from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/plugins/20.swal.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  pwa_client_Eorgdr12jA,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _00_sentry_MgFydIFahU,
  _01_bus_uVOTHEAQvV,
  _01_cron_VFpgOqTWAz,
  _01_hotjar_client_eGAfoWrJCs,
  _01_localStorage_OTdFX3RGvx,
  _01_vuetify_ldQcUM9Q1b,
  _02_api_bgyRz33mcA,
  _10_identity_KRcqj1o6Px,
  _15_push_client_mN3a0hmkLI,
  _20_resumable_MUpQ4xvMld,
  _20_swal_client_vczlhVeYNn
]