<template>
  <NuxtLayout>
    <VitePwaManifest />
    <NuxtLoadingIndicator :color="loader.color" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
  ref,
  watch,
  computed,
  onMounted,
  onBeforeUnmount,
  onUpdated,
  nextTick,
} from 'vue'
import { colorToCssWithAlpha } from '~/libs/colors'
import { getDebugger } from '~/libs/debug'
import { useStatusStore } from '~/stores/status'
import type { Bus, BusEvent } from '~/libs/bus'
import type { MiliCron } from '@jakguru/milicron'
import type { Identity } from '~/libs/identity'
import type { WatchStopHandle } from 'vue'
import type Hotjar from '@hotjar/browser'
import type { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import type { Resumables } from '~/plugins/20.resumable'
import type { LocalStorage } from '~/libs/localStorage'
import type { PushService } from '~/libs/push'
import type Swal from 'sweetalert2'
import type { Axios } from 'axios'
import type { NotificationPayload } from 'firebase/messaging'

const debug = getDebugger('App')
const cronDebug = getDebugger('Cron')

export default defineComponent({
  name: 'App',
  setup() {
    debug(`Version: ${import.meta.env.VITE_VERSION}`)
    const router = useRouter()
    const bus = inject<Bus>('bus')
    const cron = inject<MiliCron>('cron')
    const identity = inject<Identity>('identity')
    const hotjar = inject<typeof Hotjar>('hotjar')
    const newrelic = inject<BrowserAgent>('newrelic')
    const resumables = inject<Resumables>('resumables')
    const ls = inject<LocalStorage>('ls')
    const push = inject<PushService>('push')
    const toast = inject<typeof Swal>('toast')
    const api = inject<Axios>('api')
    const pwa = useNuxtApp().$pwa
    const { t } = useI18n({ useScope: 'global' })
    const loaded = ref({
      livechat: false as boolean | null,
      solitics: false as boolean | null,
      maxmind: false as boolean | null,
      localstorage: false as boolean | null,
      cron: false as boolean | null,
      resumables: false as boolean | null,
    })
    const windowCallBacks = Object.assign(
      {},
      ...Object.keys(loaded.value).map((k) => {
        const windowEvent = [k, 'loaded'].join(':')
        const busEvent = ['loaded', k].join(':') as BusEvent
        return {
          [windowEvent]: () => {
            if (bus) {
              bus.emit(busEvent, { local: true })
            }
          },
        }
      }),
      ...Object.keys(loaded.value).map((k) => {
        const windowEvent = [k, 'failed'].join(':')
        const busEvent = ['failed', k].join(':') as BusEvent
        return {
          [windowEvent]: () => {
            if (bus) {
              bus.emit(busEvent, { local: true })
            }
          },
        }
      })
    )
    const busCallBacks = Object.assign(
      {},
      ...Object.keys(loaded.value).map((k) => {
        const busEvent = ['loaded', k].join(':') as BusEvent
        return {
          [busEvent]: () => {
            loaded.value[k as keyof typeof loaded.value] = true
          },
        }
      }),
      ...Object.keys(loaded.value).map((k) => {
        const busEvent = ['failed', k].join(':') as BusEvent
        return {
          [busEvent]: () => {
            loaded.value[k as keyof typeof loaded.value] = null
          },
        }
      })
    )
    const onOpenLiveChat = () => {
      if (loaded.value.livechat) {
        window.LiveChatWidget!.init()
        window.LiveChatWidget!.call('maximize')
      }
    }
    const onIdentityLoggedIn = (
      _bearer: string,
      _expiration: string,
      name: string,
      email: string
    ) => {
      /**
       * Inform hotjar, newrelic and livechat of the user's identity
       */
      if (hotjar) {
        hotjar.identify(email, {
          name,
          email,
        })
      }
      if (newrelic) {
        newrelic.setUserId(email)
      }
      if (window.LiveChatWidget) {
        window.LiveChatWidget.call('set_customer_name', name)
        window.LiveChatWidget.call('set_customer_email', email)
      }
    }
    const onLoginEmailUpdated = (email: string) => {
      /**
       * Inform hotjar, newrelic and livechat of the user's email
       */
      if (hotjar) {
        hotjar.identify(email, {
          email,
        })
      }
      if (newrelic) {
        newrelic.setUserId(email)
      }
      if (window.LiveChatWidget) {
        window.LiveChatWidget.call('set_customer_email', email)
      }
    }
    const onPushPermissionGranted = () => {
      if (toast) {
        toast.fire({
          icon: 'success',
          title: t('successes.push.enabled.title'),
          text: t('successes.push.enabled.text'),
        })
      }
    }
    const onPushPermissionDenied = () => {
      if (toast) {
        toast.fire({
          icon: 'warning',
          title: t('warnings.push.rejected.title'),
          text: t('warnings.push.rejected.text'),
        })
      }
    }
    const onPushNotification = (payload: NotificationPayload) => {
      const { body, image, title } = payload
      const notificationTitle = title || body
      const info: any = {
        icon: 'info',
        title: notificationTitle,
        text: notificationTitle !== body ? body : undefined,
        timer: 30000,
        showCloseButton: true,
      }
      if (image) {
        info.iconHtml = `<img src="${image}" alt="${notificationTitle}" style="width: 40px; height: 40px;" />`
      }
      if (toast) {
        toast.fire(info)
      }
    }
    const statusStore = useStatusStore()
    let doBackgroundUpdateTimeout: NodeJS.Timeout | undefined
    const doBackgroundUpdate = () => {
      if (statusStore.loaded && api && 'undefined' !== typeof window) {
        debug('Doing Background Update')
        statusStore.fetch(api)
      }
    }
    const onBackgroundUpdated = () => {
      if (doBackgroundUpdateTimeout) {
        clearTimeout(doBackgroundUpdateTimeout)
      }
      doBackgroundUpdateTimeout = setTimeout(doBackgroundUpdate, 100)
    }
    const ready = computed(() => Object.values(loaded.value).every((v) => v === true || v === null))
    const setLayout = () => {
      if (!window) {
        debug('This can only occur in the browser')
        return
      }
      if (identity) {
        if (ready.value && identity.booted.value) {
          debug('Updating Layout')
          if (identity.authenticated.value) {
            setPageLayout('authenticated')
          } else {
            setPageLayout('login')
          }
        } else {
          debug('Setting to Loading Layout', {
            ready: ready.value,
            booted: identity.booted.value,
          })
          setPageLayout('default')
        }
      }
    }
    const clearStorageOnLogout = () => {
      if (ls && loaded.value.localstorage) {
        ls.set('wizard.acknowledged', false)
      }
    }
    let readyWatchCanceller: WatchStopHandle | undefined
    let authenticatedWatchCanceller: WatchStopHandle | undefined
    let identityBootedWatchCanceller: WatchStopHandle | undefined
    let pushBootedWatchCanceller: WatchStopHandle | undefined
    onMounted(() => {
      if (identity) {
        authenticatedWatchCanceller = watch(
          () => identity.authenticated.value,
          (isAuthenticated) => {
            debug('Authenticated Changed', { isAuthenticated })
            nextTick(() => {
              setLayout()
            })
          }
        )
        identityBootedWatchCanceller = watch(
          () => identity.booted.value,
          (is, was) => {
            debug('Identity Booted Changed', { is, was })
            if (is) {
              nextTick(() => {
                setLayout()
              })
            }
          },
          {
            immediate: true,
          }
        )
      }
      if (push) {
        pushBootedWatchCanceller = watch(
          () => push.booted.value,
          (is, was) => {
            debug('Push Booted Changed', { is, was })
            if (is) {
              nextTick(() => {
                setLayout()
              })
            }
          },
          {
            immediate: true,
          }
        )
      }
      readyWatchCanceller = watch(
        () => ready.value,
        (isReady) => {
          debug('Ready Changed', { isReady })
          if (isReady && identity && !identity.booted.value) {
            debug('Booting Identity Provider')
            identity.boot()
          }
          if (isReady && push && !push.booted.value) {
            debug('Booting Push Service')
            push.boot()
          }
        },
        {
          immediate: true,
        }
      )
      if (cron) {
        cron.$once('* * * * * * *', () => {
          loaded.value.cron = true
          cronDebug('Cron Daemon Started')
        })
        cron.start()
      }
      if (window) {
        if (!window._bvc_loaded) {
          window._bvc_loaded = {}
        }
        for (const key in windowCallBacks) {
          window.addEventListener(key, windowCallBacks[key])
        }
        debug('Added Window Event Listeners', Object.keys(windowCallBacks))
        for (const key in window._bvc_loaded) {
          if (true === window._bvc_loaded[key as keyof typeof window._bvc_loaded]) {
            windowCallBacks[`${key}:loaded`]()
          } else if (null === window._bvc_loaded[key as keyof typeof window._bvc_loaded]) {
            windowCallBacks[`${key}:failed`]()
          }
        }
      }
      if (bus) {
        for (const key in busCallBacks) {
          bus.on(key as BusEvent, busCallBacks[key], { local: true, immediate: true })
        }
        bus.on('livechat:open', onOpenLiveChat, { local: true })
        bus.on('identity:login', setLayout, { local: true })
        bus.on('identity:logout', setLayout, { local: true })
        bus.on('identity:logout', clearStorageOnLogout, { local: true })
        bus.on('identity:login', onIdentityLoggedIn, {
          local: true,
          crossTab: true,
          immediate: true,
        })
        bus.on('login:email:updated', onLoginEmailUpdated, {
          local: true,
          crossTab: true,
          immediate: true,
        })
        bus.on('push:permission:granted', onPushPermissionGranted, { local: true })
        bus.on('push:permission:denied', onPushPermissionDenied, { local: true })
        bus.on('push:notification', onPushNotification, { local: true })
        bus.on('background:evidence:updated', onBackgroundUpdated, { local: true })
        bus.on('background:pii:updated', onBackgroundUpdated, { local: true })
        bus.on('background:profile:updated', onBackgroundUpdated, { local: true })
        bus.on('background:rejected:evidence', onBackgroundUpdated, { local: true })
        bus.on('background:rejected:rsaid', onBackgroundUpdated, { local: true })
        bus.on('background:rsaid:rejected:mismatch', onBackgroundUpdated, { local: true })
        bus.on('background:docfox:rsaid:rejected', onBackgroundUpdated, { local: true })
        bus.on('background:updated:poi', onBackgroundUpdated, { local: true })
        bus.on('background:docfox:application:create:failed', onBackgroundUpdated, { local: true })
        bus.on('background:evidence:rejected', onBackgroundUpdated, { local: true })
        debug('Added Bus Event Listeners', Object.keys(busCallBacks))
      }
      if (resumables) {
        loaded.value.resumables = true
      }
      nextTick(() => {
        if (ready.value && identity && !identity.booted.value) {
          debug('Booting Identity Provider')
          identity.boot()
        } else if (ready.value && (!identity || identity.booted.value)) {
          debug('Not changing layout', {
            ready: ready.value,
            identity: identity,
            booted: identity?.booted.value,
          })
        } else if (ready.value) {
          setLayout()
        }
        if (ready.value && push && !push.booted.value) {
          debug('Booting Push Service')
          push.boot()
        }
        debug({ pwa })
      })
    })
    onUpdated(() => {
      nextTick(() => {
        if (ready.value && identity && !identity.booted.value) {
          debug('Booting Identity Provider')
          identity.boot()
        } else if (ready.value && (!identity || identity.booted.value)) {
          debug('Changing layout anyway', {
            ready: ready.value,
            identity: identity,
            booted: identity?.booted.value,
          })
          setLayout()
        } else if (ready.value) {
          setLayout()
        }
        if (ready.value && push && !push.booted.value) {
          debug('Booting Push Service')
          push.boot()
        }
      })
    })
    onBeforeUnmount(() => {
      if (cron) {
        cronDebug('Stopping Cron Daemon')
        cron.stop()
      }
      if (window) {
        for (const key in windowCallBacks) {
          window.removeEventListener(key, windowCallBacks[key])
        }
        debug('Removed Window Event Listeners', Object.keys(windowCallBacks))
      }
      if (bus) {
        for (const key in busCallBacks) {
          bus.off(key as BusEvent, busCallBacks[key], { local: true })
        }
        bus.off('livechat:open', onOpenLiveChat, { local: true })
        bus.off('identity:login', setLayout, { local: true })
        bus.off('identity:logout', setLayout, { local: true })
        bus.off('identity:logout', clearStorageOnLogout, { local: true })
        bus.off('identity:login', onIdentityLoggedIn, { local: true, crossTab: true })
        bus.off('login:email:updated', onLoginEmailUpdated, { local: true, crossTab: true })
        bus.off('push:permission:granted', onPushPermissionGranted, { local: true })
        bus.off('push:permission:denied', onPushPermissionDenied, { local: true })
        bus.off('push:notification', onPushNotification, { local: true })
        bus.off('background:evidence:updated', onBackgroundUpdated, { local: true })
        bus.off('background:pii:updated', onBackgroundUpdated, { local: true })
        bus.off('background:profile:updated', onBackgroundUpdated, { local: true })
        bus.off('background:rejected:evidence', onBackgroundUpdated, { local: true })
        bus.off('background:rejected:rsaid', onBackgroundUpdated, { local: true })
        bus.off('background:rsaid:rejected:mismatch', onBackgroundUpdated, { local: true })
        bus.off('background:docfox:rsaid:rejected', onBackgroundUpdated, { local: true })
        bus.off('background:updated:poi', onBackgroundUpdated, { local: true })
        bus.off('background:docfox:application:create:failed', onBackgroundUpdated, { local: true })
        bus.off('background:evidence:rejected', onBackgroundUpdated, { local: true })
        debug('Removed Bus Event Listeners', Object.keys(busCallBacks))
      }
      if (identity) {
        identity.shutdown()
      }
      if (push) {
        push.shutdown()
      }
      if (readyWatchCanceller) {
        readyWatchCanceller()
      }
      if (authenticatedWatchCanceller) {
        authenticatedWatchCanceller()
      }
      if (identityBootedWatchCanceller) {
        identityBootedWatchCanceller()
      }
      if (pushBootedWatchCanceller) {
        pushBootedWatchCanceller()
      }
    })
    useHead(
      {
        script: [
          {
            key: 'solitics',
            src: 'https://sdk.solitics.com/oapit.min.js?v=' + Date.now(),
            async: true,
            onload: () => {
              if (window) {
                if (!window._bvc_loaded) {
                  window._bvc_loaded = {}
                }
                window._bvc_loaded.solitics = true
                const event = new CustomEvent('solitics:loaded', { detail: {} })
                window.dispatchEvent(event)
              }
            },
            onerror: () => {
              if (window) {
                if (!window._bvc_loaded) {
                  window._bvc_loaded = {}
                }
                window._bvc_loaded.solitics = true
                const event = new CustomEvent('solitics:loaded', { detail: {} })
                window.dispatchEvent(event)
              }
            },
          },
        ],
      },
      {
        mode: 'client',
      }
    )
    router.beforeEach(() => {
      clearError()
      setLayout()
    })
    router.afterEach(() => {
      nextTick(() => {
        setLayout()
      })
    })
    return {
      loaded,
      ready,
      identity,
      loader: {
        color: colorToCssWithAlpha('primary'),
      },
      // pwa: push?.pwa
    }
  },
})
</script>

<style lang="scss">
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}
</style>
