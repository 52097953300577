import { default as poiPF8iCP6dfJMeta } from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/evidence/poi.vue?macro=true";
import { default as porkXxoyNqAggMeta } from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/evidence/por.vue?macro=true";
import { default as indexSvZ9pNdcawMeta } from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/index.vue?macro=true";
import { default as national_45idrS9TYrSbVeMeta } from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/national-id.vue?macro=true";
import { default as questionnairefx86b5R0MVMeta } from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/questionnaire.vue?macro=true";
import { default as component_45stubXa56w0JNXzMeta } from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubXa56w0JNXz } from "/builds/nht4944308/brands/banxso/banxso-verification-center/gui/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: "evidence-poi___en",
    path: "/evidence/poi",
    component: () => import("/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/evidence/poi.vue").then(m => m.default || m)
  },
  {
    name: "evidence-por___en",
    path: "/evidence/por",
    component: () => import("/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/evidence/por.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "national-id___en",
    path: "/national-id",
    component: () => import("/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/national-id.vue").then(m => m.default || m)
  },
  {
    name: "questionnaire___en",
    path: "/questionnaire",
    component: () => import("/builds/nht4944308/brands/banxso/banxso-verification-center/gui/pages/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: component_45stubXa56w0JNXzMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubXa56w0JNXz
  }
]