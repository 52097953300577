import { FakeResumable, createResumableInstance } from '../libs/resumable'
import type { ResumableLike } from '../libs/resumable'

export interface Resumables {
  poi: {
    front: ResumableLike
    back: ResumableLike
  }
  por: {
    front: ResumableLike
    back: ResumableLike
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const poiFront =
    'undefined' !== typeof window
      ? createResumableInstance(
          runtimeConfig.public.VITE_API_HOST,
          '/evidence/poi/upload/front',
          nuxtApp.vueApp.config.globalProperties.$ls!
        )
      : new FakeResumable({})
  const poiBack =
    'undefined' !== typeof window
      ? createResumableInstance(
          runtimeConfig.public.VITE_API_HOST,
          '/evidence/poi/upload/back',
          nuxtApp.vueApp.config.globalProperties.$ls!
        )
      : new FakeResumable({})
  const porFront =
    'undefined' !== typeof window
      ? createResumableInstance(
          runtimeConfig.public.VITE_API_HOST,
          '/evidence/por/upload/front',
          nuxtApp.vueApp.config.globalProperties.$ls!
        )
      : new FakeResumable({})
  const porBack =
    'undefined' !== typeof window
      ? createResumableInstance(
          runtimeConfig.public.VITE_API_HOST,
          '/evidence/por/upload/back',
          nuxtApp.vueApp.config.globalProperties.$ls!
        )
      : new FakeResumable({})
  const instance: Resumables = {
    poi: {
      front: poiFront,
      back: poiBack,
    },
    por: {
      front: porFront,
      back: porBack,
    },
  }
  nuxtApp.vueApp.provide('resumables', instance)
  nuxtApp.vueApp.config.globalProperties.$resumables = instance
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $resumables?: Resumables
  }
  interface ComponentCustomProps {
    $resumables?: Resumables
  }
  interface InjectionKey<T> extends Symbol {
    resumables: Resumables
  }
}
