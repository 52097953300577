import { Bus } from '../libs/bus'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const instance = new Bus(runtimeConfig.public.namespace)
  nuxtApp.vueApp.provide('bus', instance)
  nuxtApp.vueApp.config.globalProperties.$bus = instance
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $bus?: Bus
  }
  interface ComponentCustomProps {
    $bus?: Bus
  }
  interface InjectionKey<T> extends Symbol {
    bus: Bus
  }
}
