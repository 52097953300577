import { MiliCron } from '@jakguru/milicron'
import { getDebugger } from '../libs/debug'

const debug = getDebugger('Cron')

export default defineNuxtPlugin((nuxtApp) => {
  const instance = new MiliCron()
  nuxtApp.vueApp.provide('cron', instance)
  nuxtApp.vueApp.config.globalProperties.$cron = instance
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $cron?: MiliCron
  }
  interface ComponentCustomProps {
    $cron?: MiliCron
  }
  interface InjectionKey<T> extends Symbol {
    cron: MiliCron
  }
}
