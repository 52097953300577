import { Identity } from '../libs/identity'

export default defineNuxtPlugin((nuxtApp): void => {
  const instance = new Identity(
    nuxtApp.vueApp.config.globalProperties.$bus!,
    nuxtApp.vueApp.config.globalProperties.$ls!,
    nuxtApp.vueApp.config.globalProperties.$cron!,
    nuxtApp.vueApp.config.globalProperties.$api!
  )
  nuxtApp.vueApp.provide('identity', instance)
  nuxtApp.vueApp.config.globalProperties.$identity = instance
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $identity?: Identity
  }
  interface ComponentCustomProps {
    $identity?: Identity
  }
  interface InjectionKey<T> extends Symbol {
    identity: Identity
  }
}
